.login-panel {
  padding-top: 4%;
}

.login-logo {
  margin-bottom: 4%;
}

.welcome-logo {
  margin-left: 35%;
  margin-bottom: 1%;
	max-height: 30%;
	max-width: 30%;
}

.brand-logo {
  max-height: 200%;
  max-width: 200%;
}

/* MARKETING CONTENT
-------------------------------------------------- */

.marketing .sections {
  margin-top: 9%;
}

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 20px;
  text-align: center;
}
.marketing h2 {
  font-weight: normal;
}
.marketing .col-lg-4 p {
  margin-right: 10px;
  margin-left: 10px;
}

div.marketing.row {
	margin-top: 10%;
	margin-left: 10%;
}

.marketing.row {
  margin-top: 15%;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {
  /* Navbar positioning foo */
  .navbar-wrapper {
    margin-top: 20px;
  }
  .navbar-wrapper .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar-wrapper .navbar {
    padding-right: 0;
    padding-left: 0;
  }

  /* The navbar becomes detached from the top, so we round the corners */
  .navbar-wrapper .navbar {
    border-radius: 4px;
  }

}

