$mainFontColor: #333333;
$navFontColor: white;

$brandGreen: rgba(47, 138, 42, 1);

$blue: #337ab7;
$yellow: #EFCC00;

.blue {
    color: $blue;
}

.yellow {
    color: $yellow;
}

.red {
    color: red;
}