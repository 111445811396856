@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "font-awesome/font-awesome";

@import "colors";
@import "nav";
@import "landing";
@import "forms";
@import "posts";
@import "users";
@import "media";
@import "courses";
@import "spinner";
@import "tables";
@import "support";

@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans);
//font-family: 'Lato', sans-serif;

// Vue hide until loaded
[v-cloak] {
    display: none;
}

body {
	color: $mainFontColor;
	opacity: 87%;
	font-family: 'Alegreya Sans', sans-serif;
}

.installers {
	table {
		font-size: 18px;
	}
}

textarea {
	width: 100%;
	min-width: 100%;
	max-width:100%;

	height: 70px;
	min-height: 70px;
	max-height: 70px;
}

.protected {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.subnav {
	margin-top: -1.5%;
	margin-bottom: -1%;
}

.subnav-image {
	max-width: 70%;
	margin-left: 12%;
}

.nav-logo {
	max-height: 26%;
	max-width: 26%;
}

.add-button {
	margin-bottom: 5px;
}

.inline {
	display: inline;
}

.black-link {
	color: $mainFontColor;
}

