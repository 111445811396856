.no-systems {
	margin-top: 8%;
}

.users-table {
    .name {
        max-width: 20%;
    }
    .email {
        max-width: 75%;
    }
    .mobile-connection {
        max-width: 5%;
    }
}