/* navbar */
.navbar-default {
    background-color: $brandGreen;
    border-color: darken($brandGreen, 5%);
}

/* title */
.navbar-default .navbar-brand {
    color: white;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: black;
}

/* link */
.navbar-default .navbar-nav > li > a {
    color: white;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #333;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #E7E7E7;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: white;
    background-color: darken($brandGreen, 5%);
}

/* caret */
.navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: white;
    border-bottom-color: white;
}

.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #333;
    border-bottom-color: #333;
}

.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: white;
    border-bottom-color: white;
}

/* mobile version */
.navbar-default .navbar-toggle {
    border-color: darken($brandGreen, 5%);
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: darken($brandGreen, 5%);
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: white;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: black;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: white;
    }
}